import React from "react"
import AppContext from "@contexts/AppContext"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "@components/Layout"
import SEO from "@components/SEO"

import PostContentStyle from "@styles/PostStyle"
import PostContentStyleOriginal from "@styles/PostStyleOriginal"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { shortCodes } from "@components/mdx"


const Wrapper = styled.div`

`

const PostLayout = styled.article`
  width: 100%;
  border-radius: 2px;
  background: ${({ theme }) => theme.background.paper};
  ${({ theme }) => theme.dp.one};
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`

const PostTop = styled.div``

const PostInfo = styled.header`
  padding: 30px;
  h1 {
    margin: 0 0 0.5em;
    font-size: 2em;
    font-weight: 600;
    color: ${({ theme }) => theme.text.post};
  }
  .tag {
    display: inline-block;
  }
  .breadcrumbs {
    margin: 0 0 15px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        display: inline-flex;
        line-height: 24px;
        :first-child::before {
          content: "";
          padding: 0;
        }
        ::before {
          padding: 0 0.5em;
          color: ${({ theme }) => theme.text.secondary};
          content: ">";
        }
        a {
          display: inline-flex;
          color: ${({ theme }) => theme.text.secondary};
          text-align: center;
          font-size: 16px;
          text-decoration: none;
          white-space: nowrap;
          svg {
            width: 24px;
            height: 21px;
            fill: ${({ theme }) => theme.text.secondary};
            vertical-align: text-bottom;
          }
          :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .MuiBreadcrumbs-separator {
    color: ${({ theme }) => theme.text.secondary};
  }
`

const PostTime = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  .dateTime {
    display: inline-flex;
    margin-bottom: 5px;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    color: ${({ theme }) => theme.text.secondary};
    svg {
      width: 16px;
      height: 16px;
      fill: ${({ theme }) => theme.text.secondary};
      vertical-align: bottom;
    }
  }
`

const PostContent = styled.div`
  padding: 30px;
  ${PostContentStyle}
  ${PostContentStyleOriginal}
`


const PageTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const post = data.mdx
  const title = post.frontmatter.title
  const postDescription = post.frontmatter.description
  const postDate = post.frontmatter.date
  const postUpdate = post.frontmatter.update
  const slug = post.fields.slug
  const postBODY = post.body
  const TOC = post.tableOfContents

  const dateStr = postDate.replace("-", "年").replace("-", "月") + "日"
  const updateStr = postUpdate !== null && postUpdate.replace("-", "年").replace("-", "月") + "日"

  return (
    <AppContext.Provider value={{ title, slug, TOC }}>
      <Layout location={location} title={siteTitle}>
        <Wrapper>
          <SEO
            title={title}
            description={postDescription || post.excerpt}
            type={`article`}
            url={slug}
            image={null}
          />
          <PostLayout>
            <PostTop>
              <PostInfo>
                {/* ぱんくずリスト */}
                <nav className="breadcrumbs">
                  <ul itemScope={true} itemType="https://schema.org/BreadcrumbList">
                    <li key="1" itemProp="itemListElement" itemScope={true} itemType="https://schema.org/ListItem">
                      <Link key="home" itemProp="item" to={`/`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                        </svg>
                        <span itemProp="name">Home</span>
                      </Link>
                      <meta itemProp="position" content="1" />
                    </li>
                  </ul>
                </nav>
                {/* タイトル */}
                <h1 itemProp="headline">{title}</h1>
                {/* 公開日・更新日 */}
                <PostTime>
                  {postUpdate === null ?
                    <p className="dateTime">
                      <time dateTime={postDate}>
                        公開日
                        :{dateStr}
                      </time>
                    </p> :
                    <>
                      <p className="dateTime">
                        公開日
                        :{dateStr}
                      </p>
                      <p className="dateTime">
                        <time dateTime={postUpdate}>
                          最終更新日
                          :{updateStr}
                        </time>
                      </p>
                    </>
                  }
                </PostTime>
              </PostInfo>
            </PostTop>
            <PostContent>
              <div>
                <MDXProvider components={shortCodes}>
                  <MDXRenderer>
                    {postBODY}
                  </MDXRenderer>
                </MDXProvider>
              </div>
            </PostContent>
          </PostLayout>
        </Wrapper>
      </Layout>
    </AppContext.Provider>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
        categories {
          name
          slug
        }
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        update(formatString: "YYYY-MM-DD")
        description
      }
    }
  }
`
